import {Component, ViewEncapsulation} from '@angular/core';
import {LanguageService} from '../services/language.service';

@Component({
  selector: 'mnet-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectorComponent {
  public currentLanguage = 'de';

  constructor(public languageService: LanguageService) {
  }

  changeLanguage(event) {
    this.languageService.changeLanguage(event);
    this.currentLanguage = event;
  }

}
